r-grid {
  display:grid;
  --grid-tc:repeat(4,1fr);
  grid-template-columns:var(--grid-tc);
  --grid-cs:1;
  --grid-ce:-1;
  grid-gap:var(--column-gap);
  grid-row-gap:var(--row-gap)
}
r-grid.compact {
  --column-gap:var(--column-gap-compact);
  --row-gap:var(--row-gap-compact)
}
r-cell {
  display:block;
  -moz-appearance:none;
  appearance:none;
  -webkit-appearance:none
}
r-grid[columns="1"] {
  --grid-tc:repeat(1,1fr)
}
r-grid[columns="2"] {
  --grid-tc:repeat(2,1fr)
}
r-grid[columns="3"] {
  --grid-tc:repeat(3,1fr)
}
r-grid[columns="4"] {
  --grid-tc:repeat(4,1fr)
}
r-grid[columns="5"] {
  --grid-tc:repeat(5,1fr)
}
r-grid[columns="6"] {
  --grid-tc:repeat(6,1fr)
}
r-grid[columns="7"] {
  --grid-tc:repeat(7,1fr)
}
r-grid[columns="8"] {
  --grid-tc:repeat(8,1fr)
}
r-grid[columns="9"] {
  --grid-tc:repeat(9,1fr)
}
r-grid[columns="10"] {
  --grid-tc:repeat(10,1fr)
}
r-grid[columns="11"] {
  --grid-tc:repeat(11,1fr)
}
r-grid[columns="12"] {
  --grid-tc:repeat(12,1fr)
}
r-grid[columns="13"] {
  --grid-tc:repeat(13,1fr)
}
r-grid[columns="14"] {
  --grid-tc:repeat(14,1fr)
}
r-grid[columns="15"] {
  --grid-tc:repeat(15,1fr)
}
r-grid[columns="16"] {
  --grid-tc:repeat(16,1fr)
}
r-cell[span^="1"] {
  --grid-cs:1
}
r-cell[span^="2"] {
  --grid-cs:2
}
r-cell[span^="3"] {
  --grid-cs:3
}
r-cell[span^="4"] {
  --grid-cs:4
}
r-cell[span^="5"] {
  --grid-cs:5
}
r-cell[span^="6"] {
  --grid-cs:6
}
r-cell[span^="7"] {
  --grid-cs:7
}
r-cell[span^="8"] {
  --grid-cs:8
}
r-cell[span^="9"] {
  --grid-cs:9
}
r-cell[span^="10"] {
  --grid-cs:10
}
r-cell[span^="11"] {
  --grid-cs:11
}
r-cell[span^="12"] {
  --grid-cs:12
}
r-cell[span^="13"] {
  --grid-cs:13
}
r-cell[span^="14"] {
  --grid-cs:14
}
r-cell[span^="15"] {
  --grid-cs:15
}
r-cell[span^="16"] {
  --grid-cs:16
}
r-cell[span$="+1"],
r-cell[span="1"] {
  --grid-ce:1
}
r-cell[span$="+2"],
r-cell[span$="-1"],
r-cell[span="2"] {
  --grid-ce:2
}
r-cell[span$="+3"],
r-cell[span$="-2"],
r-cell[span="3"] {
  --grid-ce:3
}
r-cell[span$="+4"],
r-cell[span$="-3"],
r-cell[span="4"] {
  --grid-ce:4
}
r-cell[span$="+5"],
r-cell[span$="-4"],
r-cell[span="5"] {
  --grid-ce:5
}
r-cell[span$="+6"],
r-cell[span$="-5"],
r-cell[span="6"] {
  --grid-ce:6
}
r-cell[span$="+7"],
r-cell[span$="-6"],
r-cell[span="7"] {
  --grid-ce:7
}
r-cell[span$="+8"],
r-cell[span$="-7"],
r-cell[span="8"] {
  --grid-ce:8
}
r-cell[span$="+9"],
r-cell[span$="-8"],
r-cell[span="9"] {
  --grid-ce:9
}
r-cell[span$="+10"],
r-cell[span$="-9"],
r-cell[span="10"] {
  --grid-ce:10
}
r-cell[span$="+11"],
r-cell[span$="-10"],
r-cell[span="11"] {
  --grid-ce:11
}
r-cell[span$="+12"],
r-cell[span$="-11"],
r-cell[span="12"] {
  --grid-ce:12
}
r-cell[span$="+13"],
r-cell[span$="-12"],
r-cell[span="13"] {
  --grid-ce:13
}
r-cell[span$="+14"],
r-cell[span$="-13"],
r-cell[span="14"] {
  --grid-ce:14
}
r-cell[span$="+15"],
r-cell[span$="-14"],
r-cell[span="15"] {
  --grid-ce:15
}
r-cell[span$="+16"],
r-cell[span$="-15"],
r-cell[span="16"] {
  --grid-ce:16
}
r-cell[span$="-16"] {
  --grid-ce:17
}
r-cell[span] {
  grid-column-end:span var(--grid-ce)
}
r-cell[span*="+"],
r-cell[span*="-"],
r-cell[span*=".."] {
  grid-column-start:var(--grid-cs)
}
r-cell[span*="-"],
r-cell[span*=".."] {
  grid-column-end:var(--grid-ce)
}
r-cell[span=row] {
  grid-column:1/-1
}
r-cell[order="-16"] {
  order:-16
}
r-cell[order="-15"] {
  order:-15
}
r-cell[order="-14"] {
  order:-14
}
r-cell[order="-13"] {
  order:-13
}
r-cell[order="-12"] {
  order:-12
}
r-cell[order="-11"] {
  order:-11
}
r-cell[order="-10"] {
  order:-10
}
r-cell[order="-9"] {
  order:-9
}
r-cell[order="-8"] {
  order:-8
}
r-cell[order="-7"] {
  order:-7
}
r-cell[order="-6"] {
  order:-6
}
r-cell[order="-5"] {
  order:-5
}
r-cell[order="-4"] {
  order:-4
}
r-cell[order="-3"] {
  order:-3
}
r-cell[order="-2"] {
  order:-2
}
r-cell[order="-1"] {
  order:-1
}
r-cell[order="0"] {
  order:0
}
r-cell[order="1"] {
  order:1
}
r-cell[order="2"] {
  order:2
}
r-cell[order="3"] {
  order:3
}
r-cell[order="4"] {
  order:4
}
r-cell[order="5"] {
  order:5
}
r-cell[order="6"] {
  order:6
}
r-cell[order="7"] {
  order:7
}
r-cell[order="8"] {
  order:8
}
r-cell[order="9"] {
  order:9
}
r-cell[order="10"] {
  order:10
}
r-cell[order="11"] {
  order:11
}
r-cell[order="12"] {
  order:12
}
r-cell[order="13"] {
  order:13
}
r-cell[order="14"] {
  order:14
}
r-cell[order="15"] {
  order:15
}
r-cell[order="16"] {
  order:16
}
@media (max-width:1024px) {
  r-grid[columns-s="1"] {
    --grid-tc:repeat(1,1fr)
  }
  r-grid[columns-s="2"] {
    --grid-tc:repeat(2,1fr)
  }
  r-grid[columns-s="3"] {
    --grid-tc:repeat(3,1fr)
  }
  r-grid[columns-s="4"] {
    --grid-tc:repeat(4,1fr)
  }
  r-grid[columns-s="5"] {
    --grid-tc:repeat(5,1fr)
  }
  r-grid[columns-s="6"] {
    --grid-tc:repeat(6,1fr)
  }
  r-grid[columns-s="7"] {
    --grid-tc:repeat(7,1fr)
  }
  r-grid[columns-s="8"] {
    --grid-tc:repeat(8,1fr)
  }
  r-grid[columns-s="9"] {
    --grid-tc:repeat(9,1fr)
  }
  r-grid[columns-s="10"] {
    --grid-tc:repeat(10,1fr)
  }
  r-grid[columns-s="11"] {
    --grid-tc:repeat(11,1fr)
  }
  r-grid[columns-s="12"] {
    --grid-tc:repeat(12,1fr)
  }
  r-grid[columns-s="13"] {
    --grid-tc:repeat(13,1fr)
  }
  r-grid[columns-s="14"] {
    --grid-tc:repeat(14,1fr)
  }
  r-grid[columns-s="15"] {
    --grid-tc:repeat(15,1fr)
  }
  r-grid[columns-s="16"] {
    --grid-tc:repeat(16,1fr)
  }
  r-cell[span-s^="1"] {
    --grid-cs:1
  }
  r-cell[span-s^="2"] {
    --grid-cs:2
  }
  r-cell[span-s^="3"] {
    --grid-cs:3
  }
  r-cell[span-s^="4"] {
    --grid-cs:4
  }
  r-cell[span-s^="5"] {
    --grid-cs:5
  }
  r-cell[span-s^="6"] {
    --grid-cs:6
  }
  r-cell[span-s^="7"] {
    --grid-cs:7
  }
  r-cell[span-s^="8"] {
    --grid-cs:8
  }
  r-cell[span-s^="9"] {
    --grid-cs:9
  }
  r-cell[span-s^="10"] {
    --grid-cs:10
  }
  r-cell[span-s^="11"] {
    --grid-cs:11
  }
  r-cell[span-s^="12"] {
    --grid-cs:12
  }
  r-cell[span-s^="13"] {
    --grid-cs:13
  }
  r-cell[span-s^="14"] {
    --grid-cs:14
  }
  r-cell[span-s^="15"] {
    --grid-cs:15
  }
  r-cell[span-s^="16"] {
    --grid-cs:16
  }
  r-cell[span-s$="+1"],
  r-cell[span-s="1"] {
    --grid-ce:1
  }
  r-cell[span-s$="+2"],
  r-cell[span-s$="-1"],
  r-cell[span-s="2"] {
    --grid-ce:2
  }
  r-cell[span-s$="+3"],
  r-cell[span-s$="-2"],
  r-cell[span-s="3"] {
    --grid-ce:3
  }
  r-cell[span-s$="+4"],
  r-cell[span-s$="-3"],
  r-cell[span-s="4"] {
    --grid-ce:4
  }
  r-cell[span-s$="+5"],
  r-cell[span-s$="-4"],
  r-cell[span-s="5"] {
    --grid-ce:5
  }
  r-cell[span-s$="+6"],
  r-cell[span-s$="-5"],
  r-cell[span-s="6"] {
    --grid-ce:6
  }
  r-cell[span-s$="+7"],
  r-cell[span-s$="-6"],
  r-cell[span-s="7"] {
    --grid-ce:7
  }
  r-cell[span-s$="+8"],
  r-cell[span-s$="-7"],
  r-cell[span-s="8"] {
    --grid-ce:8
  }
  r-cell[span-s$="+9"],
  r-cell[span-s$="-8"],
  r-cell[span-s="9"] {
    --grid-ce:9
  }
  r-cell[span-s$="+10"],
  r-cell[span-s$="-9"],
  r-cell[span-s="10"] {
    --grid-ce:10
  }
  r-cell[span-s$="+11"],
  r-cell[span-s$="-10"],
  r-cell[span-s="11"] {
    --grid-ce:11
  }
  r-cell[span-s$="+12"],
  r-cell[span-s$="-11"],
  r-cell[span-s="12"] {
    --grid-ce:12
  }
  r-cell[span-s$="+13"],
  r-cell[span-s$="-12"],
  r-cell[span-s="13"] {
    --grid-ce:13
  }
  r-cell[span-s$="+14"],
  r-cell[span-s$="-13"],
  r-cell[span-s="14"] {
    --grid-ce:14
  }
  r-cell[span-s$="+15"],
  r-cell[span-s$="-14"],
  r-cell[span-s="15"] {
    --grid-ce:15
  }
  r-cell[span-s$="+16"],
  r-cell[span-s$="-15"],
  r-cell[span-s="16"] {
    --grid-ce:16
  }
  r-cell[span-s$="-16"] {
    --grid-ce:17
  }
  r-cell[span-s] {
    grid-column-end:span var(--grid-ce)
  }
  r-cell[span-s*="+"],
  r-cell[span-s*="-"],
  r-cell[span-s*=".."] {
    grid-column-start:var(--grid-cs)
  }
  r-cell[span-s*="-"],
  r-cell[span-s*=".."] {
    grid-column-end:var(--grid-ce)
  }
  r-cell[span-s=row] {
    grid-column:1/-1
  }
  r-cell[order-s="-16"] {
    order:-16
  }
  r-cell[order-s="-15"] {
    order:-15
  }
  r-cell[order-s="-14"] {
    order:-14
  }
  r-cell[order-s="-13"] {
    order:-13
  }
  r-cell[order-s="-12"] {
    order:-12
  }
  r-cell[order-s="-11"] {
    order:-11
  }
  r-cell[order-s="-10"] {
    order:-10
  }
  r-cell[order-s="-9"] {
    order:-9
  }
  r-cell[order-s="-8"] {
    order:-8
  }
  r-cell[order-s="-7"] {
    order:-7
  }
  r-cell[order-s="-6"] {
    order:-6
  }
  r-cell[order-s="-5"] {
    order:-5
  }
  r-cell[order-s="-4"] {
    order:-4
  }
  r-cell[order-s="-3"] {
    order:-3
  }
  r-cell[order-s="-2"] {
    order:-2
  }
  r-cell[order-s="-1"] {
    order:-1
  }
  r-cell[order-s="0"] {
    order:0
  }
  r-cell[order-s="1"] {
    order:1
  }
  r-cell[order-s="2"] {
    order:2
  }
  r-cell[order-s="3"] {
    order:3
  }
  r-cell[order-s="4"] {
    order:4
  }
  r-cell[order-s="5"] {
    order:5
  }
  r-cell[order-s="6"] {
    order:6
  }
  r-cell[order-s="7"] {
    order:7
  }
  r-cell[order-s="8"] {
    order:8
  }
  r-cell[order-s="9"] {
    order:9
  }
  r-cell[order-s="10"] {
    order:10
  }
  r-cell[order-s="11"] {
    order:11
  }
  r-cell[order-s="12"] {
    order:12
  }
  r-cell[order-s="13"] {
    order:13
  }
  r-cell[order-s="14"] {
    order:14
  }
  r-cell[order-s="15"] {
    order:15
  }
  r-cell[order-s="16"] {
    order:16
  }
}
@media (max-width:640px) {
  r-grid[columns-xs="1"] {
    --grid-tc:repeat(1,1fr)
  }
  r-grid[columns-xs="2"] {
    --grid-tc:repeat(2,1fr)
  }
  r-grid[columns-xs="3"] {
    --grid-tc:repeat(3,1fr)
  }
  r-grid[columns-xs="4"] {
    --grid-tc:repeat(4,1fr)
  }
  r-grid[columns-xs="5"] {
    --grid-tc:repeat(5,1fr)
  }
  r-grid[columns-xs="6"] {
    --grid-tc:repeat(6,1fr)
  }
  r-grid[columns-xs="7"] {
    --grid-tc:repeat(7,1fr)
  }
  r-grid[columns-xs="8"] {
    --grid-tc:repeat(8,1fr)
  }
  r-grid[columns-xs="9"] {
    --grid-tc:repeat(9,1fr)
  }
  r-grid[columns-xs="10"] {
    --grid-tc:repeat(10,1fr)
  }
  r-grid[columns-xs="11"] {
    --grid-tc:repeat(11,1fr)
  }
  r-grid[columns-xs="12"] {
    --grid-tc:repeat(12,1fr)
  }
  r-grid[columns-xs="13"] {
    --grid-tc:repeat(13,1fr)
  }
  r-grid[columns-xs="14"] {
    --grid-tc:repeat(14,1fr)
  }
  r-grid[columns-xs="15"] {
    --grid-tc:repeat(15,1fr)
  }
  r-grid[columns-xs="16"] {
    --grid-tc:repeat(16,1fr)
  }
  r-cell[span-xs^="1"] {
    --grid-cs:1
  }
  r-cell[span-xs^="2"] {
    --grid-cs:2
  }
  r-cell[span-xs^="3"] {
    --grid-cs:3
  }
  r-cell[span-xs^="4"] {
    --grid-cs:4
  }
  r-cell[span-xs^="5"] {
    --grid-cs:5
  }
  r-cell[span-xs^="6"] {
    --grid-cs:6
  }
  r-cell[span-xs^="7"] {
    --grid-cs:7
  }
  r-cell[span-xs^="8"] {
    --grid-cs:8
  }
  r-cell[span-xs^="9"] {
    --grid-cs:9
  }
  r-cell[span-xs^="10"] {
    --grid-cs:10
  }
  r-cell[span-xs^="11"] {
    --grid-cs:11
  }
  r-cell[span-xs^="12"] {
    --grid-cs:12
  }
  r-cell[span-xs^="13"] {
    --grid-cs:13
  }
  r-cell[span-xs^="14"] {
    --grid-cs:14
  }
  r-cell[span-xs^="15"] {
    --grid-cs:15
  }
  r-cell[span-xs^="16"] {
    --grid-cs:16
  }
  r-cell[span-xs$="+1"],
  r-cell[span-xs="1"] {
    --grid-ce:1
  }
  r-cell[span-xs$="+2"],
  r-cell[span-xs$="-1"],
  r-cell[span-xs="2"] {
    --grid-ce:2
  }
  r-cell[span-xs$="+3"],
  r-cell[span-xs$="-2"],
  r-cell[span-xs="3"] {
    --grid-ce:3
  }
  r-cell[span-xs$="+4"],
  r-cell[span-xs$="-3"],
  r-cell[span-xs="4"] {
    --grid-ce:4
  }
  r-cell[span-xs$="+5"],
  r-cell[span-xs$="-4"],
  r-cell[span-xs="5"] {
    --grid-ce:5
  }
  r-cell[span-xs$="+6"],
  r-cell[span-xs$="-5"],
  r-cell[span-xs="6"] {
    --grid-ce:6
  }
  r-cell[span-xs$="+7"],
  r-cell[span-xs$="-6"],
  r-cell[span-xs="7"] {
    --grid-ce:7
  }
  r-cell[span-xs$="+8"],
  r-cell[span-xs$="-7"],
  r-cell[span-xs="8"] {
    --grid-ce:8
  }
  r-cell[span-xs$="+9"],
  r-cell[span-xs$="-8"],
  r-cell[span-xs="9"] {
    --grid-ce:9
  }
  r-cell[span-xs$="+10"],
  r-cell[span-xs$="-9"],
  r-cell[span-xs="10"] {
    --grid-ce:10
  }
  r-cell[span-xs$="+11"],
  r-cell[span-xs$="-10"],
  r-cell[span-xs="11"] {
    --grid-ce:11
  }
  r-cell[span-xs$="+12"],
  r-cell[span-xs$="-11"],
  r-cell[span-xs="12"] {
    --grid-ce:12
  }
  r-cell[span-xs$="+13"],
  r-cell[span-xs$="-12"],
  r-cell[span-xs="13"] {
    --grid-ce:13
  }
  r-cell[span-xs$="+14"],
  r-cell[span-xs$="-13"],
  r-cell[span-xs="14"] {
    --grid-ce:14
  }
  r-cell[span-xs$="+15"],
  r-cell[span-xs$="-14"],
  r-cell[span-xs="15"] {
    --grid-ce:15
  }
  r-cell[span-xs$="+16"],
  r-cell[span-xs$="-15"],
  r-cell[span-xs="16"] {
    --grid-ce:16
  }
  r-cell[span-xs$="-16"] {
    --grid-ce:17
  }
  r-cell[span-xs] {
    grid-column-end:span var(--grid-ce)
  }
  r-cell[span-xs*="+"],
  r-cell[span-xs*="-"],
  r-cell[span-xs*=".."] {
    grid-column-start:var(--grid-cs)
  }
  r-cell[span-xs*="-"],
  r-cell[span-xs*=".."] {
    grid-column-end:var(--grid-ce)
  }
  r-cell[span-xs=row] {
    grid-column:1/-1
  }
  r-cell[order-xs="-16"] {
    order:-16
  }
  r-cell[order-xs="-15"] {
    order:-15
  }
  r-cell[order-xs="-14"] {
    order:-14
  }
  r-cell[order-xs="-13"] {
    order:-13
  }
  r-cell[order-xs="-12"] {
    order:-12
  }
  r-cell[order-xs="-11"] {
    order:-11
  }
  r-cell[order-xs="-10"] {
    order:-10
  }
  r-cell[order-xs="-9"] {
    order:-9
  }
  r-cell[order-xs="-8"] {
    order:-8
  }
  r-cell[order-xs="-7"] {
    order:-7
  }
  r-cell[order-xs="-6"] {
    order:-6
  }
  r-cell[order-xs="-5"] {
    order:-5
  }
  r-cell[order-xs="-4"] {
    order:-4
  }
  r-cell[order-xs="-3"] {
    order:-3
  }
  r-cell[order-xs="-2"] {
    order:-2
  }
  r-cell[order-xs="-1"] {
    order:-1
  }
  r-cell[order-xs="0"] {
    order:0
  }
  r-cell[order-xs="1"] {
    order:1
  }
  r-cell[order-xs="2"] {
    order:2
  }
  r-cell[order-xs="3"] {
    order:3
  }
  r-cell[order-xs="4"] {
    order:4
  }
  r-cell[order-xs="5"] {
    order:5
  }
  r-cell[order-xs="6"] {
    order:6
  }
  r-cell[order-xs="7"] {
    order:7
  }
  r-cell[order-xs="8"] {
    order:8
  }
  r-cell[order-xs="9"] {
    order:9
  }
  r-cell[order-xs="10"] {
    order:10
  }
  r-cell[order-xs="11"] {
    order:11
  }
  r-cell[order-xs="12"] {
    order:12
  }
  r-cell[order-xs="13"] {
    order:13
  }
  r-cell[order-xs="14"] {
    order:14
  }
  r-cell[order-xs="15"] {
    order:15
  }
  r-cell[order-xs="16"] {
    order:16
  }
}



r-grid.debug>* {
  --color:rgba(248,110,91,0.3);
  background-image:linear-gradient(180deg,var(--color) 0,var(--color))
}
r-grid.debug>:nth-child(6n+2) {
  --color:rgba(103,126,208,0.3)
}
r-grid.debug>:nth-child(6n+3) {
  --color:rgba(224,174,72,0.3)
}
r-grid.debug>:nth-child(6n+4) {
  --color:rgba(77,214,115,0.3)
}
r-grid.debug>:nth-child(6n+5) {
  --color:rgba(217,103,219,0.3)
}
r-grid.debug>:nth-child(6n+6) {
  --color:rgba(94,204,211,0.3)
}
r-grid.debug>:nth-child(6n+7) {
  --color:rgba(248,110,91,0.3)
}
