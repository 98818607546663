/*

Variables

*/
:root {
  /*
  Font size is the basis of all other measurements.
  It's either constant or viewport-relative. Choose one.

  Constant: */
/*   --fontSize: 16px; */

  

/*   Veiwport-relative (100% / EMs-across-viewport)  */
  --fontSize: calc(100vw / 80);
}
@media only screen and (max-width: 600px) { :root {
  --fontSize: calc(100vw / 30);
}}
:root {
  --sansFont: "Inter", sans-serif;
  --monoFont: "iaw-mono";  /* "IBM Plex Mono" */
/* CSS */

  /* heading size */
  --h1-size: 2.8rem;
  --h2-size: 2.2rem;
  --h3-size: 1.4rem;
  --h4-size: 1.1rem;



  --unit:calc(var(--fontSize)*0.5);
  /* line height is the basis for vertical spacing */
  --line-height:calc(var(--fontSize)*1.5);
  --lineHeight:var(--line-height);

  /* horizontal and vertical spacing basis */
  --baseline:var(--unit);
  --body-padding-x:calc(var(--unit)*8);
  --body-padding-y:calc(var(--unit)*8);
  --body-bleed:calc(var(--unit)*16);
  --body-max-width:calc(var(--unit)*152);
  --blockSpacingTop:0px;
  --blockSpacingBottom:var(--line-height);
  --hr-weight:1px;
  --hrThickness:var(--hr-weight);
  --h1-margin-bottom:var(--line-height);
  --h3-margin-bottom:calc(var(--unit)*0.5);
  
  /* grid spacing */
  --column-gap:calc(var(--unit)*4);   /* changed from: 8 */
  --row-gap:calc(var(--unit)*4);      /* changed from: 8 */
  --column-gap-compact:calc(var(--unit)*4);
  --row-gap-compact:calc(var(--unit)*2);


  /* 1 pixel */
  --displayScale: 1;
  --pixel: 1px;


  /* Colors */
  --foreground-color-rgb:0,0,0;
  --foreground-color-a:1;
  --foreground-color-a-dimmed:0.4;
  --foreground-color:rgba(var(--foreground-color-rgb),var(--foreground-color-a));
  --foreground-color-dimmed:rgba(var(--foreground-color-rgb),var(--foreground-color-a-dimmed));
  --rule-color:rgba(var(--foreground-color-rgb),var(--foreground-color-a));
  --background-color:#fff;
  --letterbox-color:#333;
  --box-link-hover-color:rgba(var(--foreground-color-rgb),0.07);
  --accent-color:var(--blue);
  --accent-color-darkmode:#9ac9fe;
  --menu-active-color:var(--foreground-color);
  --menu-inactive-color:rgba(0,0,0,0.3);
  --red:#f03009;
  --red-orange:#ff2e00;
  --hot-orange:#ff5c00;
  --orange:#ff7105;
  --brown:#716558;
  --warm-yellow:#ffe310;
  --yellow:#fff310;
  --light-yellow:#ffffb3;
  --green:#12c05b;
  --ocean-green:#008f5d;
  --pear-green:#77dd86;
  --blue:#0366d6;
  --sunset-pink:#ff7b81;
  --light-pink:#ffb3ba;
  --xlight-pink:#ffd1d9






}



@supports (color:color(display-p3 1 1 1)) {
  :root {
    --red:color(display-p3 0.94 0.19 0.04);
    --red-orange:color(display-p3 0.97 0.26 0.11);
    --hot-orange:color(display-p3 1.0 0.36 0.0);
    --orange:color(display-p3 0.99 0.49 0.16);
    --brown:color(display-p3 0.44 0.393 0.341);
    --warm-yellow:color(display-p3 1.0 0.87 0.05);
    --yellow:color(display-p3 1.0 0.96 0.05);
    --light-yellow:color(display-p3 1.0 1.0 0.7);
    --ocean-green:color(display-p3 0.0 0.55 0.37);
    --sunset-pink:color(display-p3 1 0.495 0.54);
    --light-pink:color(display-p3 1 0.69 0.718);
    --xlight-pink:color(display-p3 1 0.845 0.869);

    --grey-light:color(display-p3 .95 0.95 0.95);
  }
}



@media only screen and (-webkit-min-device-pixel-ratio:1.5),only screen and (min-resolution:1.5dppx) {
  :root {
    --displayScale:1.5;
    --pixel:0.75px
  }
}
@media only screen and (-webkit-min-device-pixel-ratio:2.0),only screen and (min-resolution:2.0dppx) {
  :root {
    --displayScale:2;
    --pixel:0.5px
  }
}
@media only screen and (-webkit-min-device-pixel-ratio:2.5),only screen and (min-resolution:2.5dppx) {
  :root {
    --displayScale:2.5;
    --pixel:0.4px
  }
}
@media only screen and (-webkit-min-device-pixel-ratio:3.0),only screen and (min-resolution:3.0dppx) {
  :root {
    --displayScale:3;
    --pixel:0.3333333333px
  }
}
@media only screen and (-webkit-min-device-pixel-ratio:3.5),only screen and (min-resolution:3.5dppx) {
  :root {
    --displayScale:4;
    --pixel:0.25px
  }
}
/*@media only screen and (max-device-width: 812px) and (orientation: landscape) { :root {
  --fontSize: 1.7vw;
}}*/
