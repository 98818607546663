/* column flow */
[flow-cols-l],
[flow-cols-s],
[flow-cols] {
  display:block;
  -moz-column-count:3;
  column-count:3;
  -moz-column-gap:var(--column-gap);
  column-gap:var(--column-gap);
  -moz-column-fill:balance;
  column-fill:balance;
  orphans:3;
  widows:3
}
[flow-cols-l]>h1,
[flow-cols-l]>h2,
[flow-cols-l]>hr,
[flow-cols-s]>h1,
[flow-cols-s]>h2,
[flow-cols-s]>hr,
[flow-cols]>h1,
[flow-cols]>h2,
[flow-cols]>hr {
  -moz-column-span:all;
  column-span:all
}
[flow-cols="1"] {
  -moz-column-count:1;
  column-count:1
}
[flow-cols="2"] {
  -moz-column-count:2;
  column-count:2
}
[flow-cols="3"] {
  -moz-column-count:3;
  column-count:3
}
[flow-cols="4"] {
  -moz-column-count:4;
  column-count:4
}
[flow-cols="5"] {
  -moz-column-count:5;
  column-count:5
}
[flow-cols="6"] {
  -moz-column-count:6;
  column-count:6
}
[flow-cols="7"] {
  -moz-column-count:7;
  column-count:7
}
[flow-cols="8"] {
  -moz-column-count:8;
  column-count:8
}
@media (max-width:1024px) {
  [flow-cols-s="1"] {
    -moz-column-count:1;
    column-count:1
  }
  [flow-cols-s="2"] {
    -moz-column-count:2;
    column-count:2
  }
  [flow-cols-s="3"] {
    -moz-column-count:3;
    column-count:3
  }
  [flow-cols-s="4"] {
    -moz-column-count:4;
    column-count:4
  }
  [flow-cols-s="5"] {
    -moz-column-count:5;
    column-count:5
  }
  [flow-cols-s="6"] {
    -moz-column-count:6;
    column-count:6
  }
  [flow-cols-s="7"] {
    -moz-column-count:7;
    column-count:7
  }
  [flow-cols-s="8"] {
    -moz-column-count:8;
    column-count:8
  }
}
@media (max-width:640px) {
  [flow-cols-xs="1"] {
    -moz-column-count:1;
    column-count:1
  }
  [flow-cols-xs="2"] {
    -moz-column-count:2;
    column-count:2
  }
  [flow-cols-xs="3"] {
    -moz-column-count:3;
    column-count:3
  }
  [flow-cols-xs="4"] {
    -moz-column-count:4;
    column-count:4
  }
  [flow-cols-xs="5"] {
    -moz-column-count:5;
    column-count:5
  }
  [flow-cols-xs="6"] {
    -moz-column-count:6;
    column-count:6
  }
  [flow-cols-xs="7"] {
    -moz-column-count:7;
    column-count:7
  }
  [flow-cols-xs="8"] {
    -moz-column-count:8;
    column-count:8
  }
}