@import "vars.scss";
@import "reset.scss";
@import "grid.scss";
@import "doc.scss";
@import "flow.scss";
@import "elements.scss";
@import "helpers.scss";
@import "debug.scss";
@import "color.scss";
@import "page_designs.scss";


.logo {
  font-weight:800;
}

.logo a {
  text-decoration: none;
}



r-grid.main .menu {
  height:calc(var(--unit)*26);
  //padding-top:calc(var(--unit)*8)
}
@media (max-width:640px) {
  r-grid.main .menu {
    padding-top:0;
    height:calc(var(--unit)*30)
  }
}
r-grid.main .menu>div {
  margin:0;
  padding:0;
  color:var(--menu-inactive-color);
  display:flex;
  flex-direction:column;
  align-items:flex-start;
/*   padding-top:calc(var(--unit)*0.75); */
/*   margin-top:calc(var(--unit)*14) */
}
r-grid.main .menu>div.focus0 a:first-child {
  color:var(--menu-active-color)
}
r-grid.main .menu>div.focus1 {
  margin-top:calc(var(--unit)*10)
}
r-grid.main .menu>div.focus1 a:nth-child(2) {
  color:var(--menu-active-color)
}
r-grid.main .menu>div.focus2 {
  margin-top:calc(var(--unit)*6)
}
r-grid.main .menu>div.focus2 a:nth-child(3) {
  color:var(--menu-active-color)
}
r-grid.main .menu>div.focus3 {
  margin-top:calc(var(--unit)*2)
}
r-grid.main .menu>div.focus3 a:nth-child(4) {
  color:var(--menu-active-color)
}
r-grid.main .menu>div.focus4 {
  margin-top:calc(var(--unit)*-2)
}
r-grid.main .menu>div.focus4 a:nth-child(5) {
  color:var(--menu-active-color)
}
r-grid.main .menu>div.focus5 {
  margin-top:calc(var(--unit)*-6)
}
r-grid.main .menu>div.focus5 a:nth-child(6) {
  color:var(--menu-active-color)
}
@media (max-width:640px) {
  r-grid.main .menu>div.focus0,
  r-grid.main .menu>div.focus1,
  r-grid.main .menu>div.focus2,
  r-grid.main .menu>div.focus3,
  r-grid.main .menu>div.focus4,
  r-grid.main .menu>div.focus5 {
    margin-top:0
  }
}
r-grid.main .menu>div a {
  font-family:var(--titleFont);
  font-weight:500;
  font-size:1.5rem;
  line-height:2rem;
  letter-spacing:.01em;
  color:inherit;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  text-decoration:none;
  flex:0 0 auto
}
/* r-grid.main .menu>div a:first-child {
  font-size:2em;
  padding-bottom:calc(var(--unit)*0.25);
  margin-top:calc(var(--unit)*-0.25)
} */
r-grid.main .menu>div a:hover {
  color:var(--menu-active-color)
}
@media (max-width:640px) {
  r-grid.main {
    padding-top:calc(var(--unit)*2)
  }
}
r-grid.main iframe {
  border:none
}
r-grid.main img {
  max-width:100%
}
r-grid.main [align=right] {
  margin:0 0 calc(var(--unit)*2) calc(var(--unit)*3)
}
r-grid.main [align=left] {
  margin:0 calc(var(--unit)*3) calc(var(--unit)*2) 0
}
@media (prefers-color-scheme:dark) {
  :root.allow-dark-mode,
  :root.home {
    --background-color:#333;
    --foreground-color-rgb:255,255,255;
    --menu-inactive-color:rgba(var(--foreground-color-rgb),0.3);
    --box-link-hover-color:#000;
    --letterbox-color:#111;
    --accent-color:var(--accent-color-darkmode);
    --menu-inactive-color:rgba(var(--foreground-color-rgb),0.6)
  }
}
:root.allow-dark-mode .menu a:first-child,
:root.home .menu a:first-child {
  color:var(--hot-orange)!important
}
@media (max-width:640px) {
  :root.allow-dark-mode .intro,
  :root.home .intro {
    padding-bottom:calc(var(--unit)*4);
    border-bottom:.15rem solid var(--foreground-color)
  }
}
:root.allow-dark-mode .intro>p,
:root.home .intro>p {
  font-family:var(--titleFont);
  font-weight:500;
  font-size:1.5rem;
  line-height:2rem;
  letter-spacing:.01em
}
:root.allow-dark-mode .intro .quick-links,
:root.home .intro .quick-links {
  margin-top:calc(var(--unit)*6);
  display:flex;
  flex-direction:column;
  align-items:flex-start
}
:root.allow-dark-mode .intro .quick-links a,
:root.home .intro .quick-links a {
  font-size:inherit;
  font-weight:600;
  display:block;
  padding:calc(var(--unit)*2) calc(var(--unit)*3);
  background:rgba(var(--foreground-color-rgb),.07);
  margin-bottom:calc(var(--unit)*2);
  color:inherit;
  border-radius:2px;
  word-break:break-word
}
:root.allow-dark-mode .intro .quick-links a:hover,
:root.home .intro .quick-links a:hover {
  text-decoration:none;
  background:var(--hot-orange);
  color:#fff
}
@media (prefers-color-scheme:dark) {
  :root.archive {
    --background-color:#333;
    --foreground-color-rgb:255,255,255;
    --menu-inactive-color:rgba(var(--foreground-color-rgb),0.3);
    --box-link-hover-color:#000;
    --letterbox-color:#111;
    --accent-color:var(--accent-color-darkmode)
  }
}
:root.archive r-grid.main {
  grid-row-gap:calc(var(--unit)*3)
}
:root.archive r-grid.main hr.section {
  margin-top:calc(var(--unit)*4)
}
:root.archive r-grid.main .post,
:root.archive r-grid.main .year {
  margin-bottom:calc(var(--unit)*2)
}
:root.archive r-grid.main .post .excerpt {
  word-break:break-word
}
@media (prefers-color-scheme:dark) {
  :root.post {
    --background-color:#333;
    --foreground-color-rgb:255,255,255;
    --menu-inactive-color:rgba(var(--foreground-color-rgb),0.3);
    --box-link-hover-color:#000;
    --letterbox-color:#111;
    --accent-color:var(--accent-color-darkmode)
  }
}
:root.post r-grid.main {
  margin-top:calc(var(--unit)*6)
}
:root.post r-grid.main time {
  font-weight:500
}
:root.post r-grid.main .home {
  font-weight:500;
  opacity:.4;
  color:inherit
}
:root.post r-grid.main .home:hover {
  opacity:1;
  color:inherit;
  text-decoration:none
}
:root.post r-grid.main h1 {
  margin-top:calc(var(--unit)*5)
}
r-grid.main.simple {
  margin-top:calc(var(--unit)*6)
}
r-grid.main.simple .home {
  font-weight:500;
  opacity:.4;
  color:inherit
}
r-grid.main.simple .home:hover {
  opacity:1;
  color:inherit;
  text-decoration:none
}
r-grid.main.simple h1 {
  margin-top:calc(var(--unit)*-1)
}



:root.projects {
  --background-color:var(--xlight-pink);
  --foreground-color-rgb:0,0,0;
  --menu-inactive-color:var(--sunset-pink);
  --box-link-hover-color:#fff
}
:root.projects p {
  opacity:.5
}
:root.projects .project {
  border-radius:2px
}
:root.projects .project .image {
  display:block;
  margin-top:calc(var(--unit)*1.5);
  margin-bottom:calc(var(--unit)*1.5);
  border:calc(var(--unit)*1) solid #fff;
  box-sizing:border-box;
  border-radius:calc(var(--unit)*0.25);
  background:#fff
}
:root.projects .project .image img {
  height:calc(var(--unit)*16);
  width:100%;
  -o-object-fit:cover;
  object-fit:cover;
  opacity:.5;
  filter:grayscale(1)
}
:root.projects .project .image img:hover {
  opacity:1;
  filter:none
}



:root.workpage r-grid.main .aside {
  color:var(--foreground-color-dimmed);
  margin-top:calc(var(--line-height)*2.1)
}
:root.workpage r-grid.main .aside h3 a:hover {
  background-color:var(--orange);
  color:#000
}
:root.workpage r-grid.main .aside a {
  color:inherit
}
:root.workpage r-grid.main .aside a:hover {
  color:var(--foreground-color);
  text-decoration:none
}
:root.workpage r-grid.main .aside .pagelist {
  list-style:none;
  padding:0;
  margin-bottom:0;
  margin-top:calc(var(--line-height)*1.9 - var(--h3-margin-bottom))
}
@media (max-width:1024px) {
  :root.workpage r-grid.main .aside .pagelist {
    display:none
  }
}
:root.workpage r-grid.main .aside .pagelist li {
  padding:0;
  margin:0
}
:root.workpage r-grid.main .aside .pagelist li.current {
  --icon-offset:1.5em;
  display:flex;
  margin-left:calc(var(--icon-offset)*-1)
}
:root.workpage r-grid.main .aside .pagelist li.current:before {
  display:inline-block;
  margin:0;
  content:"→";
  width:var(--icon-offset)
}
:root.workpage r-grid.main h1 {
  margin-top:calc(var(--line-height)*2);
  margin-bottom:0
}
@media (max-width:1024px) {
  :root.workpage r-grid.main h1 {
    margin-top:0
  }
}
:root.workpage r-grid.main .body {
  grid-column-start:2;
  grid-column-end:span 4
}
@media (max-width:1024px) {
  :root.workpage r-grid.main .body {
    grid-column:1/-1
  }
}
:root.workpage r-grid.main .body r-grid.compact {
  --fontSize:13px;
  --line-height:calc(var(--fontSize)*1.4);
  --blockSpacingBottom:var(--line-height);
  font-size:var(--fontSize);
  line-height:var(--line-height);
  letter-spacing:0;
  margin-bottom:calc(var(--blockSpacingBottom)*2)
}
:root.workpage r-grid.main .body r-grid.compact h2 {
  font-size:1.5em;
  font-weight:600
}
:root.workpage r-grid.main .body r-grid.compact h3 {
  letter-spacing:0
}
:root.workpage r-grid.main .body r-grid.compact small {
  font-size:11px
}
:root.workpage r-grid.main .body small {
  color:var(--foreground-color-dimmed);
  display:block
}
:root.workpage r-grid.main .body small:first-child {
  margin-top:calc(var(--line-height)*-1);
  margin-bottom:var(--blockSpacingBottom)
}
:root.workpage r-grid.main>img,
:root.workpage r-grid.main>picture {
  grid-column:1/-1;
  --margin-y:calc(var(--row-gap)*-1 + var(--line-height)*1);
  margin-top:var(--margin-y);
  margin-bottom:var(--margin-y);
  width:100%
}
:root.photos {
  --background-color:#fff
}
@media (prefers-color-scheme:dark) {
  :root.photos {
    --background-color:#333;
    --foreground-color-rgb:255,255,255;
    --menu-inactive-color:rgba(var(--foreground-color-rgb),0.3);
    --box-link-hover-color:#000;
    --letterbox-color:#111;
    --accent-color:var(--accent-color-darkmode)
  }
}
:root.photos body {
  padding-bottom:0
}
:root.photos r-grid.main {
  margin-bottom:0
}
:root.photos r-grid.main .menu {
  height:calc(var(--unit)*35)
}
:root.photos .intro {
  font-weight:440
}
:root.photos .gallery {
  background-color:var(--background-color);
  --shadow-x:calc(var(--body-padding-x) + var(--body-bleed));
  box-shadow:var(--shadow-x) 0 0 0 var(--background-color),calc(var(--shadow-x)*-1) 0 0 0 var(--background-color);
  min-height:100vh;
  padding-top:var(--body-padding-y);
  padding-bottom:calc(var(--body-padding-y)*2)
}
.tiles {
  --minTileWidth:320px;
  --aspectRatio:1/1;
  --fontSizeS:11px;
  --fontSizeM:13px;
  --fontSizeL:14px;
  --borderWidth:1px;
  --borderOpacity:0.2;
  --avatarSize:24px;
  --metaHeightBase:24px;
  --metaHeightExtra:24px;
  --propertiesBarHeight:72px;
  --row-gap:calc(var(--unit)*4);
  display:grid;
  grid-template-columns:repeat(auto-fill,minmax(var(--minTileWidth),1fr));
  grid-gap:var(--column-gap);
  grid-row-gap:var(--row-gap)
}
.tiles .tile {
  position:relative;
  font-size:var(--fontSizeS);
  display:flex;
  flex-direction:column
}
.tiles .tile:hover {
  text-decoration:none
}
.tiles .tile * {
  cursor:default
}
.tiles .tile:before {
  content:"";
  opacity:0;
  display:block;
  height:0;
  width:0;
  margin-bottom:calc(var(--aspectRatio)*100% - var(--row-gap)/2)
}
.tiles .tile .filler {
  flex:1 1 auto;
  visibility:hidden
}
.tiles .tile .art,
.tiles .tile .filler {
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  -webkit-user-select:none;
  pointer-events:none
}
.tiles .tile .art {
  background-repeat:no-repeat;
  background-size:contain;
  background-position:50%;
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0
}
.tiles .tile:focus {
  outline:none;
  box-shadow:0 0 0 4px #fff
}
.tiles .tile:focus .art {
  box-shadow:inset 0 0 0 4px var(--background-color)
}
@media (prefers-color-scheme:dark) {
  :root.shop {
    --background-color:#333;
    --foreground-color-rgb:255,255,255;
    --menu-inactive-color:rgba(var(--foreground-color-rgb),0.3);
    --box-link-hover-color:#000;
    --letterbox-color:#111;
    --accent-color:var(--accent-color-darkmode)
  }
}
:root.notfound404 {
  --background-color:var(--ocean-green);
  --foreground-color-rgb:255,255,255;
  --menu-inactive-color:rgba(0,0,0,0.5);
  --box-link-hover-color:#000;
  --accent-color:var(--orange);
  background-color:initial
}
:root.notfound404 r-grid r-cell:first-child {
  color:#fff
}
:root.notfound404 h1 {
  --font-size:50vw;
  font-size:var(--font-size);
  font-weight:400;
  font-feature-settings:"cv02" on;
  margin:0;
  padding:0;
  line-height:.7;
  text-align:center;
  width:100vw;
  position:absolute;
  left:0;
  top:0;
  pointer-events:none;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  color:var(--hot-orange);
  mix-blend-mode:multiply;
  z-index:2;
  margin-top:calc(var(--line-height)*2)
}



r-grid.main .title
{
  height: calc(var(--line-height)*4);
  margin-top: calc(var(--unit)*10);
}


.logo-svg g path {
  fill:color(display-p3 0.94 0.19 0.04);
}


@media (max-width:640px) {
  r-grid {
    --row-gap:calc(var(--unit)*6)
  }
}

