

:root.about {
  --background-color:var(--hot-orange);
  --foreground-color-rgb:255,255,255;
  --menu-inactive-color:rgba(0,0,0,0.5);
  --box-link-hover-color:#fff;
  --accent-color:var(--yellow);
  --letterbox-color:#222
}
:root.about body {
  padding-bottom:0
}
:root.about r-grid.main {
  margin-bottom:0
}
:rootaboutk r-grid.main .menu {
  height:calc(var(--unit)*35)
}
:root.about .intro {
  font-weight:440;
  padding-bottom:calc(var(--unit)*6)
}
:root.about .contrib-list {
  --foreground-color:var(--orange);
  --background-color:var(--light-yellow);
  --font-size:3.33333vw;
  --accent-color:var(--foreground-color);
  --box-link-hover-color:var(--accent-color);
  background-color:var(--background-color);
  --shadow-x:calc(var(--body-padding-x) + var(--body-bleed));
  box-shadow:var(--shadow-x) 0 0 0 var(--background-color),calc(var(--shadow-x)*-1) 0 0 0 var(--background-color);
  font-family:var(--titleFont);
  font-weight:600;
  font-size:2rem;
  line-height:2.5rem;
  font-size:var(--font-size);
  line-height:1.2;
  color:var(--foreground-color)
}
@media (min-width:1215px) {
  :root.about .contrib-list {
    --font-size:2.5rem
  }
}
@media (max-width:640px) {
  :root.about .contrib-list {
    --font-size:5vw
  }
}
:root.about .contrib-list ::-moz-selection {
  background:#cc5800
}
:root.about .contrib-list ::selection {
  background:#cc5800
}
:root.about .contrib-list r-grid {
/*   --row-gap:cal(var(--font-size)*2); */
  padding-bottom:calc(var(--font-size)*4)
}
:root.about .contrib-list r-grid p {
  color:inherit
}
:root.about .contrib-list r-grid p a:hover {
  color:#000;
  text-decoration:none
}
@media (hover:none) {
  :root.about .contrib-list r-grid p a,
  :root.about .contrib-list r-grid p a:hover {
    text-decoration:underline;
    -webkit-text-decoration-color:var(--red);
    text-decoration-color:var(--red)
  }
}
:root.about .contrib-list h2 {
  font-family:var(--titleFont);
  font-weight:600;
  font-size:2rem;
  line-height:2.5rem;
  font-size:inherit;
  color:#000;
  margin-top:calc(var(--unit)*16);
  margin-bottom:calc(var(--unit)*12)
}








:root.work-detail {
  --background-color:#000;
  --foreground-color-rgb:255,255,255;
  --rule-color:rgba(var(--foreground-color-rgb),0.15);
  --menu-inactive-color:rgba(var(--foreground-color-rgb),0.3);
  --box-link-hover-color:#000;
  --letterbox-color:#111;
  --accent-color:var(--accent-color-darkmode);
  --menu-inactive-color:rgba(var(--foreground-color-rgb),0.6)
}








/* 
:root.about {
  --menu-inactive-color:#fff;
  --box-link-hover-color:#fff;
  --background-color:#a6e8aa;
  --accent-color:#004287
}
@supports (color:color(display-p3 1 1 1)) {
  :root.about {
    --background-color:color(display-p3 0.693 0.865 0.666);
    --accent-color:color(display-p3 0 0.346 0.47)
  }
}
:root.about .intro {
  font-family:var(--titleFont);
  font-weight:500;
  font-size:1.5rem;
  line-height:2rem;
  letter-spacing:.01em
}
@media (max-width:640px) {
  :root.about .bio {
    padding-bottom:calc(var(--unit)*4);
    border-bottom:.15rem solid var(--foreground-color)
  }
}
:root.about .bio>img {
  margin-top:calc(var(--unit)*0.5);
  border-radius:calc(var(--unit)*0.25)
}
:root.about .bio .link-list {
  font-family:var(--titleFont);
  font-weight:500;
  font-size:1.5rem;
  line-height:2rem;
  letter-spacing:.01em;
  margin-top:calc(var(--unit)*6)
}
:root.about .bio .link-list a {
  display:block;
  color:inherit
}
:root.about .tidbit {
  border-top:var(--hr-weight) solid #000;
  padding-top:calc(var(--unit)*5)
}
:root.about .tidbit p {
  font-weight:440
}
:root.about .tidbit p code,
:root.about .tidbit p pre,
:root.about .tidbit p tt {
  font-weight:400
}
 */





