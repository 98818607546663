:root {
  --debugGrey05:hsla(0,0%,47.1%,0.05);
  --debugGrey1:hsla(0,0%,47.1%,0.1);
  --debugGrey2:hsla(0,0%,47.1%,0.2);
  --debugRed2:rgba(238,39,17,0.2);
  --debugBlue2:rgba(24,113,233,0.2);
  --debugGreen2:rgba(18,192,91,0.2);
  --debugYellow2:rgba(249,191,15,0.2);
  --debugPink2:rgba(255,0,255,0.2);
  --debugCyan2:rgba(20,230,245,0.2);
  --debugCyan3:rgba(20,230,245,0.3);
  --debugGrey4:hsla(0,0%,47.1%,0.4);
  --debugRed4:rgba(238,39,17,0.4);
  --debugBlue4:rgba(24,113,233,0.4);
  --debugGreen4:rgba(18,192,91,0.4);
  --debugYellow4:rgba(249,191,15,0.4);
  --debugPink4:rgba(255,0,255,0.4);
  --debugCyan4:rgba(20,230,245,0.4);
  --debugGrey8:hsla(0,0%,47.1%,0.8);
  --debugRed8:rgba(238,39,17,0.8);
  --debugBlue8:rgba(24,113,233,0.8);
  --debugGreen8:rgba(18,192,91,0.8);
  --debugYellow8:rgba(249,191,15,0.8);
  --debugPink8:rgba(255,0,255,0.8);
  --debugCyan8:rgba(20,230,245,0.8);
  --wireWeight:var(--pixel)
}
.debug-base-grid {
  background-size:var(--unit) var(--unit);
  background-repeat:repeat;
  background-position:calc(var(--unit)*-0.5) calc(var(--unit)*-0.5);
  background-image:radial-gradient(hsla(0,0%,49.8%,.15) calc(var(--pixel)*2),transparent 0)
}
:root.debug address,
:root.debug article,
:root.debug aside,
:root.debug blockquote,
:root.debug canvas,
:root.debug dd,
:root.debug div,
:root.debug dl,
:root.debug dt,
:root.debug fieldset,
:root.debug figcaption,
:root.debug figure,
:root.debug footer,
:root.debug form,
:root.debug grid,
:root.debug h1,
:root.debug h2,
:root.debug h3,
:root.debug h4,
:root.debug h5,
:root.debug h6,
:root.debug header,
:root.debug hr,
:root.debug li,
:root.debug main,
:root.debug nav,
:root.debug noscript,
:root.debug ol,
:root.debug p,
:root.debug pre,
:root.debug section,
:root.debug table,
:root.debug tfoot,
:root.debug ul,
:root.debug video {
  box-shadow:inset 0 0 0 var(--wireWeight) var(--debugPink4),0 0 0 var(--wireWeight) var(--debugPink4),0 var(--blockSpacingBottom) 0 0 var(--debugYellow2),0 calc(var(--blockSpacingTop)*-1) 0 0 var(--debugYellow2)
}
:root.debug hr {
  box-shadow:inset 0 0 0 var(--wireWeight) var(--debugGreen4),0 0 0 var(--wireWeight) var(--debugGreen4)
}
:root.debug :first-child {
  box-shadow:inset 0 0 0 var(--wireWeight) var(--debugGreen4),0 0 0 var(--wireWeight) var(--debugGreen4),0 var(--blockSpacingBottom) 0 0 var(--debugYellow2)
}
:root.debug :last-child {
  box-shadow:inset 0 0 0 var(--wireWeight) var(--debugGreen4),0 0 0 var(--wireWeight) var(--debugGreen4),0 calc(var(--blockSpacingTop)*-1) 0 0 var(--debugYellow2)
}
:root.debug :first-child:last-child {
  box-shadow:inset 0 0 0 var(--wireWeight) var(--debugGreen4),0 0 0 var(--wireWeight) var(--debugGreen4)
}
:root.debug r-grid>* {
  box-shadow:inset 0 0 0 var(--wireWeight) var(--debugBlue8),0 0 0 var(--wireWeight) var(--debugBlue8)
}