/* conditional window size */
@media only screen and (max-width: 600px) {
  .only-large-window { display: none }
}
@media only screen and (min-width: 601px) {
  .only-small-window { display: none }
}

.span-hidden {display:none}
.span-s-hidden {display:none}
.span-xs-hidden {display:none}

/* xsmall */
@media (max-width:640px) {
  .span-s-hidden {display:none}
  .span-xs-hidden {display:inline}
  .span-hidden {display:none}
}

/* small */
@media (min-width:641px) {
  .span-xs-hidden {display:none;}
  .span-hidden {display:none;}
  .span-s-hidden {display:inline;}  
}

/* large */
@media (min-width:1025px) {
  .span-xs-hidden {display:none;}
  .span-hidden {display:inline;}
  .span-s-hidden {display:none;}  
}


/* visualize base grid */
:root {
  --base-grid-color1: rgba(20,  230, 245, 0.3);
  --base-grid-color2: rgba(120, 120, 120, 0.05);
}
.show-base-grid {
  background-image: repeating-linear-gradient(
    0deg,
    var(--base-grid-color2),
    var(--base-grid-color2) 1px,
    transparent 1px,
    transparent calc(var(--baseline) / 2),
    var(--base-grid-color1) calc(calc(var(--baseline) / 2)),
    var(--base-grid-color1) calc(calc(var(--baseline) / 2) + 1px),
    transparent calc(calc(var(--baseline) / 2) + 1px),
    transparent var(--baseline)
  );
  background-repeat: repeat-y;
  background-size: 100% var(--baseline);
  background-position: 0 0.5px;
}

/* text */
.single-line {
  white-space: nowrap;
  overflow: hidden; /* note: safari shows scrollbars unless y=hidden */
  text-overflow: ellipsis;
}

/* layout mode */
.block { display: block }
.inline { display: inline-block }
.flex-h { display: flex; flex-direction: row }
.flex-v { display: flex; flex-direction: column }



/* Flex Helpers */

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height:100%;
  background-color:var(--grey-light);
}


/* relative positioning */
.top {}
.left { text-align:left }
.right { text-align:right }
.bottom { }
.center { text-align:center }

.flex-v.center { align-self:center }
.flex-h .bottom { align-self:flex-end }

img.top { object-position: center top; align-self:center }
img.center { object-position: center center; align-self:center }
img.bottom { object-position: center bottom; align-self:center }
img.left.top { object-position: left top; align-self:flex-start }
img.left.center { object-position: left center; align-self:flex-start }
img.left.bottom { object-position: left bottom; align-self:flex-start }
img.right.top { object-position: right top; align-self:flex-end }
img.right.center { object-position: right center; align-self:flex-end }
img.right.bottom { object-position: right bottom; align-self:flex-end }


/* Borders */
.border-t {border-top:1px solid var(--rule-color);}
.border-b {border-bottom:1px solid var(--rule-color);}

/* spacing */
.padding0 { padding: 0 }
.padding1 { padding: calc(var(--lineHeight) * 1) }
  .padding1-t {padding-top: calc(var(--lineHeight) * 1)}
  .padding1-b {padding-bottom: calc(var(--lineHeight) * 1)}  
  .padding1-x{padding-left: calc(var(--lineHeight) * 1);padding-right: calc(var(--lineHeight) * 1)} 
.padding2 { padding: calc(var(--lineHeight) * 2) }
  .padding2-t {padding-top: calc(var(--lineHeight) * 2)}
  .padding2-b {padding-bottom: calc(var(--lineHeight) * 2)}  
  .padding2-x{padding-left: calc(var(--lineHeight) * 2);padding-right: calc(var(--lineHeight) * 2)} 
  
.padding3 { padding: calc(var(--lineHeight) * 3) }
  .padding3-t {padding-top: calc(var(--lineHeight) * 3)}
  .padding3-b {padding-bottom: calc(var(--lineHeight) * 3)}

.padding4 { padding: calc(var(--lineHeight) * 4) }

.padding5 { padding: calc(var(--lineHeight) * 5) }
.padding5 { padding: calc(var(--lineHeight) * 6) }
.padding5 { padding: calc(var(--lineHeight) * 7) }
.padding5 { padding: calc(var(--lineHeight) * 8) }
.margin0 { margin: 0 }

.margin1 { margin: calc(var(--lineHeight) * 1) }
.margin1-t { margin-top: calc(var(--lineHeight) * 1) }


.margin2 { margin: calc(var(--lineHeight) * 2) }
  .margin2-b { margin-bottom: calc(var(--lineHeight) * 2) }
.margin3 { margin: calc(var(--lineHeight) * 3) }
.margin3-b { margin-bottom: calc(var(--lineHeight) * 3) }

.margin4 { margin: calc(var(--lineHeight) * 4) }
.margin5 { margin: calc(var(--lineHeight) * 5) }
.margin5 { margin: calc(var(--lineHeight) * 6) }
.margin5 { margin: calc(var(--lineHeight) * 7) }
.margin5 { margin: calc(var(--lineHeight) * 8) }

/* size */
.w-1 { width: calc(var(--lineHeight) * 1) }
.w-2 { width: calc(var(--lineHeight) * 2) }
.w-3 { width: calc(var(--lineHeight) * 3) }
.w-4 { width: calc(var(--lineHeight) * 4) }
.w-5 { width: calc(var(--lineHeight) * 5) }
.w-6 { width: calc(var(--lineHeight) * 6) }
.w-7 { width: calc(var(--lineHeight) * 7) }
.w-8 { width: calc(var(--lineHeight) * 8) }
.w-9 { width: calc(var(--lineHeight) * 9) }
.w-10 { width: calc(var(--lineHeight) * 10) }
.w-11 { width: calc(var(--lineHeight) * 11) }
.w-12 { width: calc(var(--lineHeight) * 12) }
.w-13 { width: calc(var(--lineHeight) * 13) }
.w-14 { width: calc(var(--lineHeight) * 14) }
.w-15 { width: calc(var(--lineHeight) * 15) }
.w-16 { width: calc(var(--lineHeight) * 16) }
.w-17 { width: calc(var(--lineHeight) * 17) }
.w-18 { width: calc(var(--lineHeight) * 18) }
.w-19 { width: calc(var(--lineHeight) * 19) }
.w-20 { width: calc(var(--lineHeight) * 20) }
.w-21 { width: calc(var(--lineHeight) * 21) }
.w-22 { width: calc(var(--lineHeight) * 22) }
.w-23 { width: calc(var(--lineHeight) * 23) }
.w-24 { width: calc(var(--lineHeight) * 24) }
.w-25 { width: calc(var(--lineHeight) * 25) }
.w-26 { width: calc(var(--lineHeight) * 26) }
.w-27 { width: calc(var(--lineHeight) * 27) }
.w-28 { width: calc(var(--lineHeight) * 28) }
.w-29 { width: calc(var(--lineHeight) * 29) }
.w-30 { width: calc(var(--lineHeight) * 30) }
.w-31 { width: calc(var(--lineHeight) * 31) }
.w-32 { width: calc(var(--lineHeight) * 32) }
.w-33 { width: calc(var(--lineHeight) * 33) }
.w-34 { width: calc(var(--lineHeight) * 34) }
.w-35 { width: calc(var(--lineHeight) * 35) }
.w-36 { width: calc(var(--lineHeight) * 36) }
.w-37 { width: calc(var(--lineHeight) * 37) }
.w-38 { width: calc(var(--lineHeight) * 38) }
.w-39 { width: calc(var(--lineHeight) * 39) }
.w-40 { width: calc(var(--lineHeight) * 40) }
.w-full { width: 100%; }

.h-1 { height: calc(var(--lineHeight) * 1) }
.h-2 { height: calc(var(--lineHeight) * 2) }
.h-3 { height: calc(var(--lineHeight) * 3) }
.h-4 { height: calc(var(--lineHeight) * 4) }
.h-5 { height: calc(var(--lineHeight) * 5) }
.h-6 { height: calc(var(--lineHeight) * 6) }
.h-7 { height: calc(var(--lineHeight) * 7) }
.h-8 { height: calc(var(--lineHeight) * 8) }
.h-9 { height: calc(var(--lineHeight) * 9) }
.h-10 { height: calc(var(--lineHeight) * 10) }
.h-11 { height: calc(var(--lineHeight) * 11) }
.h-12 { height: calc(var(--lineHeight) * 12) }
.h-13 { height: calc(var(--lineHeight) * 13) }
.h-14 { height: calc(var(--lineHeight) * 14) }
.h-15 { height: calc(var(--lineHeight) * 15) }
.h-16 { height: calc(var(--lineHeight) * 16) }
.h-17 { height: calc(var(--lineHeight) * 17) }
.h-18 { height: calc(var(--lineHeight) * 18) }
.h-19 { height: calc(var(--lineHeight) * 19) }
.h-20 { height: calc(var(--lineHeight) * 20) }
.h-21 { height: calc(var(--lineHeight) * 21) }
.h-22 { height: calc(var(--lineHeight) * 22) }
.h-23 { height: calc(var(--lineHeight) * 23) }
.h-24 { height: calc(var(--lineHeight) * 24) }
.h-25 { height: calc(var(--lineHeight) * 25) }
.h-26 { height: calc(var(--lineHeight) * 26) }
.h-27 { height: calc(var(--lineHeight) * 27) }
.h-28 { height: calc(var(--lineHeight) * 28) }
.h-29 { height: calc(var(--lineHeight) * 29) }
.h-30 { height: calc(var(--lineHeight) * 30) }
.h-31 { height: calc(var(--lineHeight) * 31) }
.h-32 { height: calc(var(--lineHeight) * 32) }
.h-33 { height: calc(var(--lineHeight) * 33) }
.h-34 { height: calc(var(--lineHeight) * 34) }
.h-35 { height: calc(var(--lineHeight) * 35) }
.h-36 { height: calc(var(--lineHeight) * 36) }
.h-37 { height: calc(var(--lineHeight) * 37) }
.h-38 { height: calc(var(--lineHeight) * 38) }
.h-39 { height: calc(var(--lineHeight) * 39) }
.h-40 { height: calc(var(--lineHeight) * 40) }

/* opacity */
.opacity0  { opacity:0; }
.opacity1  { opacity:0.1; }
.opacity2  { opacity:0.2; }
.opacity3  { opacity:0.3; }
.opacity4  { opacity:0.4; }
.opacity5  { opacity:0.5; }
.opacity6  { opacity:0.6; }
.opacity7  { opacity:0.7; }
.opacity8  { opacity:0.8; }
.opacity9  { opacity:0.9; }
.opacity10 { opacity:1; }

/* colors */
:root {
  --red:    rgb(238, 39, 17);
  --blue:   rgb(24, 113, 233);
  --green:  rgb(18, 192, 91);
  --yellow: rgb(249, 191, 15);
}
.red    { color: var(--red); }    .bg-red    { background-color: var(--red); }
.blue   { color: var(--blue); }   .bg-blue   { background-color: var(--blue); }
.green  { color: var(--green); }  .bg-green  { background-color: var(--green); }
.yellow { color: var(--yellow); } .bg-yellow { background-color: var(--yellow); }
