/* document */
:root {
  font-family:var(--sansFont);
  font-size:var(--fontSize);
  line-height:var(--line-height);
  color:var(--foreground-color);
  letter-spacing:-.01em;
  -webkit-text-size-adjust:100%;
  -moz-text-size-adjust:100%;
  text-size-adjust:100%;
  font-variant-ligatures:contextual common-ligatures;
  background-color:var(--letterbox-color)
}
@media (max-width:640px) {
  :root {
    background-color:initial
  }
}
body {
  -webkit-overflow-scrolling:touch;
  scroll-behavior:smooth;
  background:var(--background-color);
  box-shadow:0 0 0 var(--body-bleed) var(--background-color);
  margin:0 auto;
  padding:var(--body-padding-y) var(--body-padding-x);
  max-width:var(--body-max-width);
  min-height:100vh;
  box-sizing:border-box
}
@media (max-width:1024px) {
  body {
    --body-padding-y:calc(var(--unit)*4)
  }
}
@media (max-width:640px) {
  body {
    --body-padding-x:calc(var(--unit)*3);
    --body-padding-y:calc(var(--unit)*2)
  }
}